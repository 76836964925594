<template>
  <div class="agree-page">
    <div class="agree_main">
      <div class="agree_pos">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>房源</el-breadcrumb-item>
          <el-breadcrumb-item>{{ details.shen_status == 0 ? '审核中' : details.shen_status == 1 ? '审核成功' : '审核失败' }}</el-breadcrumb-item>
          <el-breadcrumb-item>他人变更</el-breadcrumb-item>
          <el-breadcrumb-item class="agree_a">详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!--agree-body start-->
      <div class="record-body">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20">
              <img
                :src="details.fang_fengmian_image"
                alt=""
              />
              <div class="personage_img_marking">{{ details.basic_fang_level }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="t-flex">
                <div class="t-tag m-r-15" v-if="details.fang_type">{{ getHouseType(details.fang_type) }}</div>
                <div class="personage_ul_name">{{ details.basic_house_name }}</div>
              </div>
              <div class="personage_ul_title">
                {{ details.basic_fang_xing }} | {{ details.basic_fang_mianji }}m² | {{ details.basic_dian_number }}次 | 2天 | {{ details.basic_name }}
              </div>
              <div class="personage_ul_tags flex-wrap">
                <span class="tag" v-for="(item,index) in tagList" :key="index">{{ item }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <!-- <div class="personage_ul_price_left m-r-20">
                    <span>{{ details.basic_zong_price }}万</span>
                    <img src="../../assets/image/personage/up2.png" alt="" />
                  </div> -->
                  <div class="personage_ul_price_right">
                    <span>{{ details.basic_can_price }}元/月</span>
                    <!-- <img src="../../assets/image/personage/up1.png" alt="" /> -->
                  </div>
                </div>
                <div class="btn flexs" v-if="type == 1">
                  <el-button class="red" @click="changeState(1)">拒绝</el-button>
                  <el-button class="blue" @click="changeState(2)">同意</el-button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--agree_detail start-->
        <div class="agree_detail">
          <div class="agree_detail_box" v-if="type == 1 && details.user_pc">
            <div class="agree_detail_tit">修改人信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">姓名：</span>{{ details.user_pc.name }}
              </div>
              <div class="li w50">
                <span class="color-6">电话：</span>{{ details.user_pc.mobile }}
              </div>
              <div class="li w50">
                <span class="color-6">工号：</span>{{ details.user_pc.id }}
              </div>
              <div class="li w50">
                <span class="color-6">职位：</span>{{ getPosition(details.user_pc.position) }}
              </div>
              <div class="li w50">
                <span class="color-6">所属门店：</span>{{ details.user_pc.dian_name }}
              </div>
              
            </div>
          </div>
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">修改信息</div>
            <div class="agree_detail_item">
              <!-- <div class="li w50">
                <span class="color-6">房源等级：</span>{{ details.fang_level }}
              </div> -->
              <!-- <div class="li w50">
                <span class="color-6">参考均价：</span>{{ details.can_price }}元/m²
              </div>
              <div class="li w50">
                <span class="color-6">参考总价：</span>{{ details.zong_price }}万
              </div> -->
              
              <div class="li w50">
                <span class="color-6">修改手机号：</span>{{ details.yezhu_mobile }}
              </div>
              <div class="li w50"></div>
              <div class="li w50">
                <span class="color-6">封面图：</span>
              </div>
              <div class="li w50"></div>
              <div class="li w50">
                <div class="message-item-flex" v-if="details.fang_fengmian_image">
                  <img
                    class="flex-img"
                    :src="details.fang_fengmian_image"
                  />
                </div>
              </div>
              <div class="li w50"></div>
              <div class="li w50">
                <span class="color-6">轮播图：</span>
              </div>
              <div class="li w50">
                <span class="color-6">视频：</span>
              </div>
              
              <div class="li w50">
                <div class="message-item-flex">
                  <img
                    class="flex-img"
                    v-for="(item,index) in fang_lunbo_images" :key="index"
                    :src="item"
                  />
                </div>
              </div>
              <div class="li w50">
                <div class="message-item-flex">
                  <video :src="details.fang_vediofile"  class="flex-img"></video>
                </div>
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">其他信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">提交时间：</span>{{ details.createtime }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
        </div>
        <!--agree_detail end-->
      </div>
    </div>
  </div>
</template>

<script>
//import AgreeDetailPop from '../../components/AgreeDetailPop'
export default {
  data() {
    return {
      id:null,//
      type:1,//
      details:{},//详情
      tagList:[],//标签
      fang_lunbo_images:[],//轮播图
    };
  },
  methods:{
    //同意
    changeState (type) {
      this.$axios[type == 1 ? 'reject1' : 'agree1']({id:this.id}).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.$router.go(-1)
      })
    },
    //获取详情
    gethouseChangeDetail () {
      this.$axios.houseChangeDetail({id:this.id}).then(res=>{
        this.details = res.data
        this.tagList = res.data.basic_fang_biaoqian ? res.data.basic_fang_biaoqian.split(',') : []
        this.fang_lunbo_images = res.data.fang_lunbo_images ? res.data.fang_lunbo_images.split(',') : []
      })
    }
  },
  created () {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.gethouseChangeDetail()
  }
};
</script>

<style scoped lang="less">
.message-item-flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  .flex-img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    margin: 5px 10px;
  }
}
.report-more {
  font-size: 14px;
  color: #666;
}
.audit-list-btn {
  height: 40px;
  border-radius: 4px;
  padding: 0 24px;
  font-size: 16px;
  line-height: 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 11;
}
.audit-list-btn.blue {
  background: #3273f6;
  color: #fff;
}
.audit-list-btn.red {
  background: #fddfdf;
  color: #ff5151;
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.agree_a {
  /deep/ .el-breadcrumb__inner {
    color: #3273f6;
  }

}
/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
.agree_main {
  background: #fff;
  padding-bottom: 30px;
}
.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #eaeaea;
}
.agree_pos {
  padding: 30px 30px;
  color: #666;
}
.agree_detail {
  background: #f8fafe;
  margin: 0 50px;
  padding: 10px 30px 10px 30px;
  .agree_detail_tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .agree_detail_tit:before {
    width: 4px;
    height: 20px;
    background: #3273f6;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    display: block;
    content: "";
  }
  .agree_detail_item {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-left: 10px;
    justify-content: space-between;
  }
  .w33 {
    width: 30%;
  }
  .w50 {
    width: 45%;
  }
  .li {
    line-height: 30px;
  }
  .lis {
    line-height: 0;
  }
  .agree_detail_box {
    margin: 25px 0px;
  }
  .img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}
.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}
.personage_ul {
  .t-flex {
    display: flex;
    .t-tag {
      background: #e1ebff;
      border-radius: 3px;
      line-height: 28px;
      padding: 0px 10px;
      font-size: 14px;
      color: #3273f6;
    }
  }
  li {
    padding: 0px 50px 30px 50px;
    display: flex;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .btn {
        /deep/ .el-button {
          width: 100px;
          height: 40px;
          font-size: 16px;
          border: none;
        }
        .red {
          color: #FF5151;
          background: #FDDFDF;
          border-radius: 4px;
        }
        .blue {
          color: #FFFFFF;
          background: #3273F6;
          border-radius: 4px;
          margin-left: 20px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
